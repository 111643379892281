<template>
  <div>
    <!-- Dates -->
    <p
      v-if="formattedDates"
    >
      {{ formattedDates }}
    </p>

    <!-- Schedules -->
    <template
      v-if="period.schedules"
    >
      <div
        v-for="(schedule, scheduleIndex) in period.schedules"
        :key="scheduleIndex"
        class="my-2"
      >
        <!-- Days -->
        <p
          v-if="schedule?.days?.length > 0"
        >
          {{ getFormattedDays(schedule.days) }}
        </p>

        <!-- Hours -->
        <p
          v-for="(range, rangeIndex) in schedule.hours"
          :key="rangeIndex"
        >
          {{ getFormattedHoursRange(range) }}
        </p>
      </div>
    </template>

    <!-- Additional info -->
    <p
      v-if="period.additional_information?.[localeToUse]"
    >
      {{ period.additional_information[localeToUse] }}
    </p>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { upperCase } from 'lodash'

import useDatetime from '@shared/hooks/datetime'

const props = defineProps({
  // Period object
  period: {
    type: Object,
    default: () => ({}),
  },
  // Locale to display the period
  locale: {
    type: String,
    default: null,
  },
})

const {
  t,
  locale: appLocale,
} = useI18n()

const {
  formatDateFull,
  formatTimeSimple,
} = useDatetime()

const localeToUse = computed(() => (
  props.locale ?? appLocale.value
))

// Format dates
const formattedDates = computed(() => {
  // Check range presence
  if (props.period.dates?.from && props.period.dates?.to) {
    return t(
      'opening_hours.content.date_range_from_to',
      {
        from: formatDateFull(props.period.dates.from),
        to: formatDateFull(props.period.dates.to),
      },
    )
  }

  if (props.period.dates?.from) {
    return t(
      'opening_hours.content.date_range_from',
      {
        from: formatDateFull(props.period.dates.from),
      },
    )
  }

  if (props.period.dates?.to) {
    return t(
      'opening_hours.content.date_range_to',
      {
        to: formatDateFull(props.period.dates.to),
      },
    )
  }

  return null
})

// Format days
function getFormattedDays(days) {
  // Separate days with comma
  return days
    .map((day) => (
      t(`enums.DaysOfWeekEnum.${upperCase(day)}`)
    ))
    .join(', ')
}

// Format hours range
function getFormattedHoursRange(range) {
  // Check range presence
  if (range?.from && range?.to) {
    return t(
      'opening_hours.content.hours_range_from_to',
      {
        from: formatTimeSimple(range.from),
        to: formatTimeSimple(range.to),
      },
    )
  }

  if (range?.from) {
    return t(
      'opening_hours.content.hours_range_from',
      {
        from: formatTimeSimple(range.from),
      },
    )
  }

  if (range?.to) {
    return t(
      'opening_hours.content.hours_range_to',
      {
        to: formatTimeSimple(range.to),
      },
    )
  }

  return null
}
</script>
